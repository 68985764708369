import { Popover, Transition } from "app/headlessui";
import { avatarImgs } from "contains/fakeData";
import { Fragment } from "react";
import Avatar from "components/Avatar/Avatar";
import SwitchDarkMode2 from "components/SwitchDarkMode/SwitchDarkMode2";
import Link from "components/Link";

export default function AvatarDropdown() {
  return (
    <div className="AvatarDropdown ">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`login-header-botton`}
            >
                 Login
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-3.5 right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid grid-cols-1 gap-6 bg-white dark:bg-neutral-800 py-7 px-6">
                 

               
                    {/* ------------------ 1 --------------------- */}
                 

                    {/* ------------------ 2 --------------------- */}
                    <Link
                      href="https://client.teraconnects.com/sign-in"
                      target="_blank"
                      className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      onClick={() => close()}
                    >
                  
                      <div className="ml-4">
                        <p className="text-sm font-medium ">Client/RA login</p>
                      </div>
                    </Link>
                    {/* <Link
                      href="/login"
                      className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      onClick={() => close()}
                    >
                  
                      <div className="ml-4">
                        <p className="text-sm font-medium ">Clinicians login</p>
                      </div>
                    </Link>
                    <Link
                      href={"/login"}
                      className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      onClick={() => close()}
                    >
                  
                      <div className="ml-4">
                        <p className="text-sm font-medium ">Agency login</p>
                      </div>
                    </Link> */}
                    <Link
                      href={"/login"}
                      className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      onClick={() => close()}
                    >
                  
                      <div className="ml-4">
                        <p className="text-sm font-medium ">Marketing Partner Login</p>
                      </div>
                    </Link>

         
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
