import React, { FC } from "react";
import Logo from "components/Logo/Logo";
import MenuBar from "components/MenuBar/MenuBar";
import AvatarDropdown from "./loginbuttondropdown";
import Navigation from "components/Navigation/Navigation";
import SearchModal from "./SearchModal";
import NotifyDropdown from "./NotifyDropdown";
import "./logout.css"
export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const renderContent = () => {
    return (
      <div className="h-20 flex justify-between logout-header">
        <div className="flex items-center lg:hidden flex-1">
          <MenuBar />
        </div>

        <div className="flex items-center logout-header-logo">
          <Logo />
        </div>

        <div className="flex-[5] hidden lg:flex justify-center mx-4">
          <Navigation />
        </div>

        <div className="flex flex items-center justify-end text-slate-700 dark:text-slate-100">
          {/* <SearchModal />
          {/* <NotifyDropdown />*/}
          <AvatarDropdown /> 
        </div>
      </div>
    );
  };

  return (
    <div className="nc-MainNav2Logged sticky top-0 w-full z-40 relative z-10 bg-white dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
      <div className="container header-teraconnect-one">{renderContent()}</div>
    </div>
  );
};

export default MainNav2Logged;
