import { useEffect, useState } from "react";
import JoinNetwork from "components/Sections/mobileappdownload/mobileappdownload";
import JobCardListSlider from "components/Sections/joblist/joblist/joblistcard/jobs/jobcardslider";
import SearchJobsFilter from "components/Sections/joblist/filter/searchfilter/searchjob";
import bannerimage from "./images/bannerimage.png";
import qrtera from "./images/mobqr.png";
import { getTopJobOrganizationsApi, searchJobsApi } from "services/jobService";
import "./jobs.css";
import { Link } from "react-router-dom";
import qrteraclinicians from "./images/teraclinician.png";
import SearchJobsByCityFilter from "components/Sections/productlist/filter/searchbycity/searchbycityfileds";

export interface Job {
  branchId: number;
  branchName: string;
  cityId: number;
  cityName: string;
  clientId: number;
  clientName: string;
  contractDurationInDays: number;
  createdDate: string;
  department: number;
  departmentName: string;
  description: string;
  duties: string;
  dutyTypeId: number;
  dutyTypeName: string;
  ecr: string;
  employmentType: string;
  employmentTypeID: number;
  experienceMaximum: number;
  experienceMinimum: number;
  expiryDate: string;
  genderPreference: string;
  healthCareCategory: string;
  healthCareCategoryID: number;
  healthCareRole: string;
  id: number;
  isAccommodationProvided: boolean;
  isFoodProvided: boolean;
  isHealthInsuranceProvided: boolean;
  isPFAndESIProvided: boolean;
  isSpecialAllowancesProvided: boolean;
  isTempPosition: boolean;
  isTeraPayroll: boolean;
  isTransportationProvided: boolean;
  isWithRecruitment: boolean;
  jobCode: string;
  jobStatusID: number;
  noOfApplicants: number;
  noOfofferedApplicants: number;
  noOfPendingApplicants: number;
  noOfRejectedApplicants: number;
  noOfScheduledgApplicants: number;
  noOfSelectedApplicants: number;
  numberOfPositions: number;
  orgBranchId: number;
  orgBranchName: string;
  roleDepartmentID: number;
  salaryMaximum: number;
  salaryMinimum: number;
  salaryType: null;
  salaryTypeId: number;
  serviceEndDate: null;
  serviceStartDate: null;
  shiftEndTime: string;
  shiftStartTime: string;
  shiftType: string;
  shiftTypeID: number;
  skills: string;
  stateId: number;
  stateName: string;
  status: string;
  title: string;
  updatedDate: string;
}
export interface HiringCompanies {
  branchId: number;
  branchName: string;
  logoPath: string | null;
  about: string;
  orgType: string;
  total: number;
}
function Jobs() {
  const [jobsData, setJobsData] = useState<Job[]>([]);
  const [topHiringCompanies, setTopHiringCompanies] = useState<
    HiringCompanies[]
  >([]);

  useEffect(() => {
    searchJobsApi({
      departmentId: 0,
      dutyTypeId: 0,
      employmentTypeId: 0,
      healthCareCategoryId: 0,
      pageNumber: 1,
      pageSize: 10,
      roleDepartmentId: 0,
      salaryType: 0,
      shiftType: 0,
      statusId: 0,
      stateIds: [0],
      cityIds: [0],
      branchIds: [0],
      salaryRange: {
        min: 0,
        max: 0,
      },
      experienceRange: {
        min: 0,
        max: 0,
      },
    })
      .then((response) => {
        setJobsData(response.data.data);
      })
      .catch((err) => {
        console.log(err, "Err");
      });
    getTopJobOrganizationsApi(false).then((res) => {
      setTopHiringCompanies(res.data);
    });
  }, []);
  return (
    <div className="nc-PageHomeDemo6">
      <div className="Jobs-page-banner">
        <div className="container">
          <div className="banner-content-job">
            <h2 className="montserrat">
              Find Your
              <span>
                {" "}
                Healthcare <br />
                Dream Job!
              </span>
            </h2>
            <h5 className="montserrat">5k+ jobs for you to explore</h5>
            <SearchJobsFilter setJobsData={setJobsData} />
          </div>
          <div className="banner-image-job">
            <img src={bannerimage} alt="" />
          </div>
        </div>
      </div>
      {/* <div className="job-category-selection">
        <div className="container">
          <h2 className="servicepages-headings montserrat font-medium lg:mb-20 mb-10">
            {" "}
            Get Jobs by <span className="font-extrabold">Category</span>
          </h2>
          <JobsByCategoryFilter setJobsData={setJobsData}  />
        </div>
      </div> */}
      <div className="buysell-sections searchbycity searchcity-slider container searchjobbycity">
        <div className="prod-listing-four flex-col align-start">
          <h2 className="servicepages-headings montserrat font-medium">
            Get Jobs by <span className="font-extrabold">State</span>
          </h2>
          <h5 className="font-black regular title-custom montserrat lg:mb-20 mb-10">
            Discover job postings tailored to specific states across India.
          </h5>
        </div>
        <SearchJobsByCityFilter setJobsData={setJobsData} />
      </div>
      <div className="teraservicesolution tabsteraconnects page-jobs">
        <div className="container tabs-wrapper pricing-table">
          <h2 className="servicepages-headings montserrat font-medium capitalize  mb-10 lg:mb-20">
            Top organizations<span className="font-bold"> Hiring Now</span>
          </h2>

          <div className="tabcontent pricingtab-Tab1 job-tab-content">
            <JobCardListSlider
              perView={4}
              topHiringCompanies={topHiringCompanies}
            />
            <div className="view-all-btn">
              <Link to="/jobs/jobs-listing/" className="job-tab-view-all">
                View all
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="appdownload-main" id="download-app-now">
        <JoinNetwork
          heading="10k+ users are on the MediProHub"
          subheading="Get real-time medical jobs updates,Events & more."
          googlePlayUrl="https://play.google.com/store/apps/details?id=com.teraplatforms.jobseeker"
          appStoreUrl="https://apps.apple.com/in/app/tera-recruit-job-search/id1659265164"
          imageUrl={qrteraclinicians}
        />
      </div>
    </div>
  );
}

export default Jobs;
