import React, { useEffect, useState } from "react";

import candidateimage from "./images/candidate-image.png";
import "./candidate.css";
import { useParams } from "react-router-dom";
import { getClinicianProfileApi } from "services/clinicianService";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import {
  // ArrowTopRightOnSquareIcon,
  // ArrowUturnLeftIcon,
  // ChevronLeftIcon,
  // ChevronRightIcon,
  // XMarkIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/24/outline";

const Candidateprofile: React.FC = () => {
  const { candidateUri } = useParams();
  const [htmlContent, setHtmlContent] = useState("");
  useEffect(() => {
    const fetchHTMLFromS3 = async () => {
      try {
        const response = await getClinicianProfileApi(candidateUri);
        const content = await response.data;
        setHtmlContent(content);

      } catch (error) {
        console.error("Error fetching clinician profile", error);
      }
    };

    fetchHTMLFromS3();
  }, [candidateUri]);
// console.log(htmlContent,"htmlContent")
  // const opt = {
  //   margin: 0,
  //   padding:0,
  //   filename: "time_sheet_report.pdf",
  //   image: { type: "jpeg", quality: 0.2 },
  //   html2canvas: { scale: 2, useCORS: true },
  //   jsPDF: { unit: "in", format: "a1", orientation: "l" },
  // };
  // const printHandler = () => {
  //   const printElement = htmlContent;
  //   console.log(printElement);
  //   html2pdf().from(printElement).set(opt).save();
  // };

  return (
    <>
      <div
        className="candidate-profile-page background-white"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
       {/* <div className="justify-end p-3 absolute top-32 right-24">
        <button
          className="button rounded-3xl"
          style={{
            backgroundColor: "#1188A8",
            color: "#fff",
            padding: "6px",
          }}
          onClick={printHandler}
        >
          <span className="flex gap-1">
            <DocumentArrowDownIcon className="h-5 w-5" />
            Download PDF
          </span>
        </button>
      </div> */}
    </>
  );
};

export default Candidateprofile;
