import React from 'react';

import MySlider from 'components/MySlider';
import OurService from './ourservices'; // Assuming you have an OurService component
import './ourservices.css';
import imageSrc2 from './images/beanding.png';
import imageSrc3 from './images/maintainence.png';
import imageSrc4 from './images/staffing.png';
const serviceData = [
  {
    id: 1,
    imageSrc: imageSrc4,
    header: 'Staffing & HR Solutions',
    description: "Navigating talent shouldn't feel like a search for a needle in the haystack—let us simplify the discovery for you.",
    link: '/services/staffing-and-hr',
  },

  {
    id: 2,
    imageSrc: imageSrc3,
    header: 'Infrastructure and Operations',
    description: "Explore an enchanting array of service providers, turning the ordinary into extraordinary at your Infrastructure.",
    link: '/services/operational-services',
  },
  {
    id: 3,
    imageSrc: imageSrc2,
    header: 'Branding and Promotion',
    description: "From planning to ",
    link: '/services/branding-and-promotion',
  },
  // {
  //   id: 3,
  //   imageSrc: imageSrc2,
  //   header: 'Medical Center Setup',
  //   description: "Transform your healthcare brand effortlessly with our innovative solutions, making your impact unmistakable.",
  //   link: '/services/branding-and-promotion',
  // }

  // Add more service data objects as needed
];

interface OurServiceSliderProps {
  
}

const OurServiceSlider: React.FC<OurServiceSliderProps> = ({ }) => {
  return (
    <MySlider
      data={serviceData}
      itemPerRow={2} 

      renderItem={(service, index) => (
        <OurService
          key={index}
          imageSrc={service.imageSrc}
          header={service.header}
          description={service.description}
          link={service.link}
          
        />
      )}
         itemPerRowBreakpoints={{

          500:1,
          1024: 2,
          1280: 2,
        }}
    />
  );
};

export default OurServiceSlider;
