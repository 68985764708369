import React, { useEffect, useRef } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import FeatureService from "components/Sections/featureservice/featureservice";
import OfferService from "components/Sections/serviceoffer/serviceoffer";
import Whoweare from "components/Sections/who-we-are/whoweare";
import TestimonialSlider from "components/Sections/testimonal-comp/TestimonialSlider";

import featureservice1 from "./images/featureservice1.png";
import featureservice2 from "./images/featureservice2.png";
import featureservice3 from "./images/featureservice3.png";
import featureservice4 from "./images/featureservice4.png";

import offerservice1 from "./images/serviceoffer1.png";
import offerservice2 from "./images/serviceoffer2.png";
import offerservice3 from "./images/serviceoffer3.png";
import offerservice4 from "./images/serviceoffer4.png";
import offerservice5 from "./images/serviceoffer5.png";
import offerservice6 from "./images/serviceoffer6.png";

import backgroundfooter from "./images/Rectangle-4.webp";
import bannerimage from "./images/partnerbanner.webp";

import JoinNetwork from "components/Sections/mobileappdownload/mobileappdownload";
import qrterapartner from "./images/terapartner.png";

import r1 from "./images/r1.png";
import r2 from "./images/r2.png";
import r3 from "./images/r3.png";
import r4 from "./images/r4.png";
import partnercall from "./images/parnert-call.png"
import "./partner.css";
import { Link } from "react-router-dom";
import persondefault from "./images/person-default.png"
import icondefault from './images/logo-default.png'
const featureServiceDataList = [
  {
    image: featureservice3,
    title: "Collaborate with the Right Partners",
    subtitle:
      "Our Tera Partner allows you to collaborate with various joint ventures, medical equipment suppliers, and service providers to excel in your business.",
  },
  {
    image: featureservice4,
  
    title: "Boost Your Market Position",
    subtitle:
      "Leverage our data-driven analytics to make the right decision to optimize your market presence and growth.",
  },
  {
    image: featureservice1,
    title: "Resource Exchange Platform",
    subtitle:
      "Leverage our Tera Connects community to stay updated on the latest medical equipment, best practices, and innovative solutions ongoing in the medical industry.",
  },
  {
    image: featureservice2,
    title: "Events and Webinars",
    subtitle:
      "Join events and webinars catered to your needs to gain knowledge, network with medical industry experts, and participate in insightful discussions.",
  },
  
  
  
  // Add more feature service data objects as needed
];

const offerServiceDatalist = [
  {
    image: offerservice2,
    title: "Enhanced Market Reach",
    subtitle:
      "Elevate your market reach and expand your business horizons by choosing right partners.",
  },
  {
    image: offerservice4,
    title: "Direct Client Interaction",
    subtitle:
      "Engage directly with clients, understand their needs and provide tailored solutions.",
  },
  {
    image: offerservice5,
    title: "Strategic Branding",
    subtitle:
      "Boost your brand presence through strategic branding and promotions within the medical community.",
  },
  {
    image: offerservice1,
    title: "Efficient Quoting",
    subtitle:
      "Simplify and expedite your quoting process to reach a broader clientele.",
  },

  {
    image: offerservice3,
    title: "Strategic Networking",
    subtitle:
      "Leverage the vast network of service providers for collaborative ventures and business growth.",
  },

  {
    image: offerservice6,
    title: "Business Growth",
    subtitle:
    "Utilize the platform for comprehensive business development, exploring new opportunities and partnerships.",
  },
  // Add more feature service data objects as needed
];
const WhoweareDatalist = [
  {
    image: r1,
    title: "Suppliers",
  },
  {
    image: r2,
    title: "Service Providers",
  },
  {
    image: r3,
    title: "Equipment Providers",
  },
  {
    image: r4,
    title: "Agents",
  },
];


const customTestimonialData = [

  {
    id: 1,
    name: "Inamul Hasan",
    heading: "“",
    description: "People at Tera Connects were simply brilliant! Their seamless collaboration and tech-driven approach propelled our growth tenfold, connecting us effortlessly with medical establishments nationwide.   Tera Connects transformed our client engagement process. Their intuitive platform facilitates direct interaction, fostering trust and transparency, ultimately increasing our conversions. \n Tera Connects transformed our client engagement process. Their intuitive platform facilitates direct interaction, fostering trust and transparency, ultimately increasing our conversions.",
    rating: 5,
    imageSrc: persondefault,
    organisation: "H&H Power",
  },
  {
    id: 2,
    name: "RAVINDER PONUGOTI",
    heading: "“",
    description: "Tera Connects catalyzed our growth by enabling strategic networking and efficient quoting. Seamless collaboration with medical establishments enhanced our brand visibility. Since joining Tera Partner, Apex Medical Systems has seen an increase in inquiries from hospitals across India. Their seamless platform connects us with the right clients, and their competitive quoting system ensures we win more deals.",
    rating: 5,
    imageSrc: persondefault,
    organisation: "APEX MEDICAL SYSTEMS",
  },
  {
    id: 3,
    name: " Krishna Odnala",
    heading: "“",
    description: "Tera Connects was a game-changer for us at SV Infra and Services. Their strategic branding and direct client interaction amplified our reach, opening doors to remarkable opportunities.",
    rating: 5,
    imageSrc: persondefault,
    organisation: "SV INFRA AND SERVICES -",
  },
  {
    id: 4,
    name: "Mohammad Nazeeruddin",
    heading: "“",
    description: "Joining hands with Tera Connects was pivotal for Odyssey Healthcare. Their seamless services and direct client interaction propelled our growth, ensuring a seamless healthcare experience.",
    rating: 5,
    imageSrc: persondefault,
    organisation: "Odyssey Healthcare Services",
  },
  {
    id: 5,
    name: "Vivek Sharma",
    heading: "“",
    description: "Working with Tera Connects was transformative for Wonderfloor. Their expertise in strategic branding and efficient quoting amplified our presence, enabling impactful collaborations within the healthcare landscape.",
    rating: 5,
    imageSrc: persondefault,
    organisation: "WONDERFLOOR",
  },

  {
    id: 6,
    name: "Surya Vardhan Reddy Gunreddy",
    heading: "“",
    description: "Tera Connects delivered beyond expectations. Their tailored solutions and direct client interaction boosted our visibility, paving the way for fruitful partnerships within the healthcare sector.",
    rating: 5,
    imageSrc: persondefault,
    organisation: "GSR Media",
  },
  {
    id: 7,
    name: "Sridhar Reddy J",
    heading: "“",
    description: "Sunriseoaks Enterprises experienced an exceptional partnership with Tera Connects. Their expertise in expanding market reach, fostering direct client interactions, and streamlining quoting processes propelled our growth in the healthcare landscape.",
    rating: 5,
    imageSrc: persondefault,
    organisation: "Sunriseoaks Enterprises ",
  },
  {
    id: 8,
    name: "Ranjit Chandra",
    heading: "“",
    description: "Working alongside Tera Connects was a turning point for Earth Water Conditioners. Their focus on broadening market reach, fostering direct client relationships, and refining quoting mechanisms elevated our stature in healthcare circles.",
    rating: 5,
    imageSrc: persondefault,
    organisation: "Earth Water conditioners",
  },
  {
    id: 9,
    name: "Arun Kumar Gorremutchu",
    heading: "“",
    description: "Tera Connects helped us bring a remarkable shift in our healthcare business. Their dedication to extensive market research became our catalyst, shaping direct client connections and refining quoting processes for unparalleled success.",
    rating: 5,
    imageSrc: persondefault,
    organisation: "SAP healthcare systems",
  }
];
function Forpartners() {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const hash = location.hash;

    const scrollToSection = () => {
      if (hash) {
        const targetElement = document.querySelector(hash) as HTMLElement;
        if (targetElement) {
          // Calculate the offset to position the top of the section at 20% of the viewport height
          const offset = window.innerHeight * -1;
          
          // Calculate the target position
          const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
          
          // Scroll to the section with the offset
          window.scrollTo({
            top: targetPosition - offset,
            behavior: 'smooth',
          });
        }
      }
    };

    // Scroll to the section when the component mounts
    scrollToSection();

    // Add a listener for hash changes
    const handleHashChange = () => {
      scrollToSection();
    };

    window.addEventListener('hashchange', handleHashChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [location]);

  const handleInstallButtonClick = () => {
    // Handle scrolling when the Install App button is clicked
    const hash = "#download-app-now"; // Replace with the ID of your target section
    navigate(hash);
  };



  
  return (
    <div className="nc-PageHomeDemo6 relative [ nc-section-rounded-md ]">
      <Helmet>
        <title>
          Join Our Partner Community And Grow Your Medical Establishment
        </title>

        <meta
          name="description"
          content=" Our partner community allows you to network with fellow medical owners and keep you updated with the latest cutting edge technology and insights. Join Now!
"
        />
      </Helmet>
      <div className=" mx-auto home-hero-section for-clinic-page clients">
        <div className="container flex head-home-sec gap-extra">
          <div className="head-home-sec-centre gap">
            <h3 className="font-white regular title-custom text-center title-heading-banner montserrat ">
              Unleash{" "}
              <span className="font-sky-blue bolders banner-grandient">
                Limitless Growth
              </span>{" "}
              in Medical Industry
            </h3>
            <h5 className="font-white  text-center regular title-custom montserrat">
           Tera Partner effortlessly link service providers with medical establishments nationwide, propelling businesses to new heights through lead generation, competitive quotations, and catalyzing 
              <span className="font-sky-blue">
                <b> 10X growth</b>.
              </span>
            </h5>
            <Link to="/contact-us">
              <a href="/contact-us/" className="common-btn-custom">
                Get Started
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="27"
                    viewBox="0 0 26 27"
                    fill="none"
                  >
                    <path
                      d="M8.99945 7.14938L16.8203 14.4116L8.99946 21.6738"
                      stroke="#fff"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </a>
            </Link>
          </div>
          <div className="head-home-sec-centre">
            <img src={bannerimage} alt="Service" className="" />
          </div>
        </div>
      </div>

      <div className="tabsteraconnects background-white">
        <div className="container">
          <h2 className="servicepages-headings montserrat font-medium lg:mb-20 mb-10">
            {" "}
            Why Join <span className="font-extrabold"> Our Network?</span>
          </h2>
          <div className="featureservice-display-wrap for-parner for-parner-new">
            {/* Map over the list of feature service data and render FeatureService for each */}
            {featureServiceDataList.map((data, index) => (
              <FeatureService key={index} data={data} />
            ))}
          </div>
        </div>
      </div>

      <div className="who-we-are teraservicesolution tabsteraconnects">
        <div className="container">
          <h2 className="servicepages-headings montserrat font-medium mb-5">
          Who are our 

            <span className="font-extrabold"> Partners?</span>
          </h2>
          <h5 className="font-white regular title-custom montserrat  lg:mb-20 mb-10">
            <span className="font-sky-blue">
              <b> Tera Partner </b>
            </span>
            caters to a diverse array of service providers in the medical industry, including

          </h5>
          <div className="whoweare-wrraper featureservice-display-wrap">
            {/* Map over the list of feature service data and render FeatureService for each */}
            {WhoweareDatalist.map((data, index) => (
              <Whoweare key={index} data={data} />
            ))}
          </div>
        </div>
      </div>

      <div className="tabsteraconnects background-white">
        <div className="container">
          <h2 className="servicepages-headings montserrat font-medium mb-5">
            {" "}
            Services <span className="font-extrabold">Offered</span>
          </h2>
          <h5 className="font-black regular title-custom montserrat lg:mb-20 mb-10">
          Tera Partner addresses the unique challenges faced by service providers with an array of solutions

          </h5>
          <div className="offer-service partner featureservice-display-wrap">
            {/* Map over the list of feature service data and render FeatureService for each */}
            {offerServiceDatalist.map((data, index) => (
              <OfferService key={index} data={data} />
            ))}
          </div>
        </div>
      </div>

      <div className="lets-start-journey">
  <div className="container">
    <div className="start-journey-container">
      <div className="journey-start-text-section">
      <h2 className="font-sky-blue bolds title-custom montserrat text-start mb-5">
              Let’s Start Our Journey
            </h2>
            <h4 className="font-white regular title-custom montserrat mb-5">
            Discover how we can help you expand your wings and grow your business.
            </h4>
            <a id="scroll-link" href="#download-app-now" className="common-btn-custom blue-btn">
              Install App
            </a>

      </div>
      <div className="journey-start-image-section">
      <img src={partnercall} />
      </div>

    </div>

  </div>

</div>

      {/* <div className="container background-white">
        <div className="sec-padding ">
          <h2 className="servicepages-headings montserrat font-medium lg:mb-10 mb-10">
            {" "}
           <span className="font-extrabold">Testimonials</span>
          </h2>

          <TestimonialSlider perView={1}  testimonialData={customTestimonialData}/>
        </div>
      </div> */}
      <div className="background-white" id="download-app-now">
        <JoinNetwork
          heading="Join Our Network"
          subheading="Unlock growth with Tera Partner—connecting providers and medical establishments nationwide for 10X business success."
          googlePlayUrl="https://play.google.com/store/apps/details?id=com.teraplatforms.terapartner"
          appStoreUrl="https://apps.apple.com/in/app/tera-partner-healthcare/id6453868120"
          imageUrl={qrterapartner}
        />
      </div>
    </div>
  );
}

export default Forpartners;
