import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import { Helmet } from "react-helmet";
import FeatureService from "components/Sections/featureservice/featureservice";
import OfferService from "components/Sections/serviceoffer/serviceoffer";
import Whoweare from "components/Sections/who-we-are/whoweare";

import TestimonialSlider from "components/Sections/testimonal-comp/TestimonialSlider";
import featureservice1 from "./images/featureservice1.png";
import featureservice2 from "./images/featureservice2.png";
import featureservice3 from "./images/featureservice3.png";
import featureservice4 from "./images/featureservice4.png";

import offerservice1 from "./images/serviceoffer1.png";
import offerservice2 from "./images/serviceoffer2.png";
import offerservice3 from "./images/serviceoffer3.png";
import offerservice4 from "./images/serviceoffer4.png";

import backgroundfooter from "./images/Rectangle-4.webp";
import bannerimage from "./images/clientsbanner.webp";
import bannertransparentgif from "./images/transparent.gif";
import bannernontransparentgif from "./images/nontransparent.gif";

import JoinNetwork from "components/Sections/mobileappdownload/mobileappdownload";
import qrteraclients from "./images/teraclients.png";
import clientcall from "./images/client-call.png"


import s1 from "./images/s1.png";
import s2 from "./images/s2.png";
import s3 from "./images/s3.png";
import s4 from "./images/s4.png";
import s5 from "./images/s5.png";
import s6 from "./images/s6.png";
import s7 from "./images/s7.png";
import s8 from "./images/s8.png";
import m1 from "./images/m1.png";
import m2 from "./images/m2.png";
import m3 from "./images/m3.png";
import m4 from "./images/m4.png";
import m5 from "./images/m5.png";
import m6 from "./images/m6.png";
import m7 from "./images/m7.png";
import m8 from "./images/m8.png";

import "./clients.css";
import { Link } from "react-router-dom";
import persondefault from "./images/person-default.png"
const featureServiceDataList = [
  {
    image: featureservice1,
    title: "Effortless Recruitment",
    subtitle:
      "Streamline your hiring process with our ATS and easy recruitment solutions.  ",
  },
  {
    image: featureservice3,
    title: "Infrastructure Planning and Setup",
    subtitle:
      "From operation theaters to IT systems, your infrastructure needs are covered. ",
  },
  {
    image: featureservice2,
    title: "Branding and Promotions",
    subtitle:
      "Embark on a journey of heightened visibility, enhanced credibility, and unparalleled success in the medical industry.      ",
  },

  {
    image: featureservice4,
    title: "Medical E-Commerce",
    subtitle:
      "Buy, sell, or rent all types of medical equipment - from beds to operation theater equipment - at ease. ",
  },
  // Add more feature service data objects as needed
];

const offerServiceDatalist = [
  {
    image: offerservice1,
    title: "Effortless Management",
    subtitle:
      "Streamline operations across branches effortlessly to your medical organization.",
  },
  {
    image: offerservice2,
    title: "Strategic Partnerships",
    subtitle:
      "Connect, interact, and choose vendors tailored for your medical organization.",
  },
  {
    image: offerservice3,
    title: "Specialized Management Consulting Services",
    subtitle:
      "From getting accreditation to registration, get guidance for each process following the standard regulation practices with ease. ",
  },
  {
    image: offerservice4,
    title: "Advanced Medical Recruitment Process",
    subtitle:
      "Stay ahead in talent acquisition with our cutting-edge Applicant Tracking System designed for medical professionals and organizations.",
  },

  // Add more feature service data objects as needed
];
const WhoweareDatalist = [
  {
    image: s2,
    title: "Hospitals",
  },
  {
    image: m1,
    title: "Clinics & Polyclinics",
  },
  {
    image: s3,
    title: "Diagnostic Centers",
  },

  {
    image: s4,
    title: "Pharma Industries & Colleges",
  },
  {
    image: m2,
    title: "Medical Device Industries",
  },
  {
    image: m3,
    title: "Dental Colleges",
  },
  {
    image: m4,
    title: "Nursing Colleges",
  },
  {
    image: m5,
    title: "AYUSH Colleges",
  },
  {
    image: s1,
    title: "Medical Colleges",
  },

  {
    image: m6,
    title: "Physiotherapy Colleges",
  },

  {
    image: s5,
    title: "Recruitment Agencies",
  },
  {
    image: s8,
    title: "Wellness Centers",
  },
  {
    image: m7,
    title: "Clinical Research Organizations",
  },
  {
    image: s7,
    title: "Blood Banks",
  },
  {
    image: s6,
    title: "Rehabilitation Centers",
  },
  {
    image: m8,
    title: " Bio Medicals",
  },
];
const customTestimonialData = [

  {
    id: 1,
    name: "Raga Somasekhar Reddy",
    heading: "“",
    description: "Tera Connects transformed us into a well-known brand - Right Way Bio Pharma. Their innovative strategies and dedicated team elevated our visibility and customer engagement. Thanks to Teraconnects for guiding us on the right path in branding and promotion!",
    rating: 5,
    imageSrc: persondefault,
    organisation: "Right way bio Pharma",
  },
  {
    id: 2,
    name: "Manav Reddy",
    heading: "“",
    description: "Teraconnects delivered exceptional gas pipeline setup service for Vijaya Hospital. Their efficiency and attention to safety standards were commendable. Grateful for their expertise in ensuring a reliable and secure gas infrastructure for our healthcare facility. This is the best platform for all medical industry business needs. Highly Recommended!!!",
    rating: 5,
    imageSrc: persondefault,
    organisation: "Vijaya Hospital",
  },
  {
    id: 3,
    name: "Dr Indrasen reddy Kankanala",
    heading: "“",
    description: "Ozone Hospitals is immensely grateful to Teraconnects for their seamless assistance in insurance empanelment. Their expertise and efficient handling of the process have significantly eased our administrative burden, allowing us to focus more on patient care. Thank you, Teraconnects, for your invaluable support in optimizing our healthcare operations.",
    rating: 5,
    imageSrc: persondefault,
    organisation: "Ozone hospitals",
  },
  {
    id: 4,
    name: "Varun Perumalla",
    heading: "“",
    description: "I sincerely appreciate Tera Connects for their outstanding service in OT set up. Their meticulous planning and execution have equipped our operation theatres with state-of-the-art facilities, enhancing our ability to provide top-notch surgical care. Teraconnects' commitment to excellence is truly commendable, making them an invaluable partner in our healthcare journey.",
    rating: 5,
    imageSrc: persondefault,
    organisation: "Perumalla Hospital",
  },
  {
    id: 5,
    name: "Raghavender Reddy Anneela",
    heading: "“",
    description: "Thank you, Teraconnects, for helping Rk Gastro & Chest make quality healthcare accessible through online channels.",
    rating: 5,
    imageSrc: persondefault,
    organisation: "Rk Gastro & Chest",
  },
  {
    id: 6,
    name: "Dr Ramesh",
    heading: "“",
    description: "Our entire team is grateful for the seamless coordination, expertise, and dedication Tera Connects brought to every aspect of the setup process. Their commitment to ensuring a smooth and efficient hospital establishment has been invaluable. Thank you, Teraconnects, for being an integral part of Sri Kanthi Hospital's foundation and success.",
    rating: 5,
    imageSrc: persondefault,
    organisation: "Sri Kanthi Hospital, Kothagudam",
  }
];

function Forclients() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const hash = location.hash;

    const scrollToSection = () => {
      if (hash) {
        const targetElement = document.querySelector(hash) as HTMLElement;
        if (targetElement) {
          // Calculate the offset to position the top of the section at 20% of the viewport height
          const offset = window.innerHeight * -1;
          
          // Calculate the target position
          const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
          
          // Scroll to the section with the offset
          window.scrollTo({
            top: targetPosition - offset,
            behavior: 'smooth',
          });
        }
      }
    };

    // Scroll to the section when the component mounts
    scrollToSection();

    // Add a listener for hash changes
    const handleHashChange = () => {
      scrollToSection();
    };

    window.addEventListener('hashchange', handleHashChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [location]);

  const handleInstallButtonClick = () => {
    // Handle scrolling when the Install App button is clicked
    const hash = "#download-app-now"; // Replace with the ID of your target section
    navigate(hash);
  };


  return (
    <div className="nc-PageHomeDemo6 relative [ nc-section-rounded-md ]">
      <Helmet>
        <title>
          Stay Connected With Clinicians, Vendors, And Clients At Ease
        </title>

        <meta
          name="description"
          content="Setting up your medical infrastructure, recruiting, branding and promotion, and even more, use our platform to run your healthcare business efficiently.
"
        />
      </Helmet>
      <div className=" mx-auto home-hero-section for-clinic-page clients">
        <div className="container flex head-home-sec gap-extra">
          <div className="head-home-sec-centre gap">
            <h3 className="font-white regular title-custom text-center title-heading-banner montserrat ">
            Building Bridges For {" "}
              <span className="font-sky-blue bolders banner-grandient">
              Medical Excellence
              </span>

            </h3>
            <h5 className="font-white  text-center regular title-custom montserrat">
            One platform, multiple services—solving all your medical industry needs across India.
            </h5>
            <Link to="/contact-us">
              <a href="/contact-us/" className="common-btn-custom">
                Get Started
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="27"
                    viewBox="0 0 26 27"
                    fill="none"
                  >
                    <path
                      d="M8.99945 7.14938L16.8203 14.4116L8.99946 21.6738"
                      stroke="#fff"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </a>
            </Link>
          </div>
          <div className="head-home-sec-centre">
            <img src={bannernontransparentgif} alt="Service" className="" />
          </div>
        </div>
      </div>

      <div className="tabsteraconnects background-white">
        <div className="container">
          <h2 className="servicepages-headings montserrat font-medium lg:mb-20 mb-10">
            {" "}
            Featured<span className="font-extrabold"> Services</span>
          </h2>
          <div className="featureservice-display-wrap">
            {/* Map over the list of feature service data and render FeatureService for each */}
            {featureServiceDataList.map((data, index) => (
              <FeatureService key={index} data={data} />
            ))}
          </div>
        </div>
      </div>

      <div className="who-we-are teraservicesolution tabsteraconnects">
        <div className="container">
          <h2 className="servicepages-headings montserrat font-medium mb-5">
            Who are our <span className="font-extrabold"> Clients?</span>
          </h2>
          <h5 className="font-white regular title-custom montserrat  lg:mb-20 mb-10">
           
          We cater our services to different 
areas in the medical industry.
          </h5>
          <div className="whoweare-wrraper featureservice-display-wrap">
            {/* Map over the list of feature service data and render FeatureService for each */}
            {WhoweareDatalist.map((data, index) => (
              <Whoweare key={index} data={data} />
            ))}
          </div>
        </div>
      </div>

      <div className="tabsteraconnects background-white">
        <div className="container">
          <h2 className="servicepages-headings montserrat font-medium mb-5">
            {" "}
            Why Choose <span className="font-extrabold"> Tera Client</span>
          </h2>
          <h5 className="font-black regular title-custom montserrat lg:mb-20 mb-10">
          Tera Client addresses the challenges faced by medical organizations with an array of solutions.

          </h5>
          <div className="offer-service featureservice-display-wrap">
            {/* Map over the list of feature service data and render FeatureService for each */}
            {offerServiceDatalist.map((data, index) => (
              <OfferService key={index} data={data} />
            ))}
          </div>
        </div>
      </div>

    
<div className="lets-start-journey">
  <div className="container">
    <div className="start-journey-container">
      <div className="journey-start-text-section">
      <h2 className="font-sky-blue bolds title-custom montserrat text-start mb-5">
              Let’s Start Our Journey
            </h2>
            <h4 className="font-white regular title-custom montserrat mb-5">
            Discover how we can meet your medical industry needs.
            </h4>
            <a href="#download-app-now" className="common-btn-custom blue-btn">
              Install App
            </a>

      </div>
      <div className="journey-start-image-section">
      <img src={clientcall} />
      </div>

    </div>

  </div>

</div>
      {/* <div className="container background-white">
        <div className="sec-padding">
          <h2 className="servicepages-headings montserrat font-medium lg:mb-10 mb-10">
            {" "}
           <span className="font-extrabold">Testimonials</span>
          </h2>
          <TestimonialSlider perView={1} testimonialData={customTestimonialData}/>
        </div>
      </div> */}

      <div className="background-white" id="download-app-now">
        <JoinNetwork
          heading="Join Our Network"
          subheading="Unlock collaboration with Tera Client—connect with clinicians and vendors for exceptional medical synergy across India."
          googlePlayUrl="https://play.google.com/store/apps/details?id=com.teraplatforms.teraconnects"
          appStoreUrl="https://apps.apple.com/in/app/tera-connects/id6448921572"
          imageUrl={qrteraclients}
        />
      </div>
    </div>
  );
}

export default Forclients;
