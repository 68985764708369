import { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import ClientImage from "../../../images/hero-right-2.png";
import { Step } from "./contactform";
import "./locationform.css";
import recruitmentImg from "./images/ra-Img-svg.svg";
import clientImg from "./images/Client-Img-svg.svg";
import partnerImg from "./images/Partner-Img-svg.svg";
import clinicianImg from "./images/Clinician-Img-svg.svg";
import clinicianIcon from "./images/clinician-svg.svg";
import partnerIcon from "./images/partner-svg.svg";
import clientIcon from "./images/clinet-svg.svg";
import recruitmentIcon from "./images/ra-svg.svg";

interface UserTypeProps {
  activeStep: any;
  setActiveStep: any;
  steps: Step[];
  onUserDataChange: (data: any) => void;
}
const schema = yup.object().shape({
  userTypeID: yup.string().required("Please Select any Option"),
});

const UserTypeForm: React.FC<UserTypeProps> = ({
  activeStep,
  setActiveStep,
  steps,
  onUserDataChange,
}) => {
  const [image, setImage] = useState<string>(clinicianImg);
  const [error, setError] = useState("");

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      userTypeID: "4",
    },
    resolver: yupResolver(schema),
  });

  const { control, formState, getValues, handleSubmit, setValue } = methods;
  const { errors } = formState;
  const userTypes = [
    {
      value: 4,
      label: "Clinician",
      image: clinicianImg,
      icon: clinicianIcon,
    },
    {
      value: 5,
      label: "Client",
      image: clientImg,
      icon: clientIcon,
    },
    {
      value: 6,
      label: "Partner",
      image: partnerImg,
      icon: partnerIcon,
    },
    {
      value: 575,
      label: "Recruitment Agency",
      image: recruitmentImg,
      icon: recruitmentIcon,
    },
  ];

  const handleUserTypeDetails = () => {
    let formData = getValues();
    onUserDataChange(formData);
    if (Number(formData.userTypeID) !== 5) {
      setActiveStep(activeStep + 2);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleDisplayImageOnRight = (img: any) => {
    const selectedUserType = userTypes.find((item) => item.value === img);
    if (selectedUserType) {
      setImage(selectedUserType.image);
    }
  };

  return (
    <FormProvider {...methods}>
      <>
        <div className="flex select-user-type">
          <div className="w-1/2">
            <div className="mt-0">
              <h1
                style={{
                  color: "white",
                  fontSize: "51px",
                  paddingTop: "9px",
                }}
              >
                I am a?{" "}
              </h1>
              <div className="grid gap-12 md:grid-cols-4 mt-6">
                {userTypes?.map((item) => (
                  <div className="col-span-1">
                    <Controller
                      name="userTypeID"
                      control={control}
                      render={({ field }) => (
                        <div
                          className={`cursor-pointer rounded-lg border pr-12 ${
                            field.value == item.value.toString()
                              ? "bg-blue-400 text-white"
                              : "bg-gray-200"
                          }`}
                          style={{ height: "155px" }}
                          onClick={() => {
                            field.onChange(item.value);
                            handleDisplayImageOnRight(item.value);
                          }}
                        >
                          <div className="flex-col justify-center pt-5 pr-5 pl-3">
                            <div className="w-14 h-14 justify-center">
                              <img src={item.icon} />
                            </div>
                            <div className="overflow-truncate pt-4">
                              {item.label}
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                ))}
              </div>
              <p className="error mt-3 pt-3">{errors.userTypeID?.message}</p>
            </div>
          </div>

          <div className="w-1/2 p-16 first-step-image">
            <img src={image} className="w-full" />
          </div>
        </div>
        <div className="button-container">
          {activeStep !== 0 && activeStep !== steps.length - 1 && (
            <button
              onClick={() => setActiveStep(activeStep - 1)}
              className="button"
            >
              Previous
            </button>
          )}
          {activeStep !== steps.length - 1 && (
            <button
              onClick={() => {
                handleSubmit(handleUserTypeDetails)();
              }}
              className="button"
            >
              Next
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          )}
        </div>
      </>
    </FormProvider>
  );
};
export default UserTypeForm;
