import React, { FC, useEffect, useState } from "react";
import Logo from "components/Logo/Logo";
import MenuBar from "components/MenuBar/MenuBar";
import AvatarDropdown from "./AvatarDropdown";
import Navigation from "components/Navigation/Navigation";
import SearchModal from "./SearchModal";
import NotifyDropdown from "./NotifyDropdown";
import './mainnav2.css'

export interface MainNav2LoggedProps {}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 30) {
      setIsHeaderScrolled(true);
    } else {
      setIsHeaderScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const defaultHeaderClass = "nc-transparentheader"; // Default class for the header

  return (
    <div className={`relative z-10 border-slate-100 dark:border-slate-700 ${isHeaderScrolled ? "header-scroll" : defaultHeaderClass}`}>
      <div className={`container header-teraconnect-one ${isHeaderScrolled ? "" : defaultHeaderClass}`}>
        <div className={`h-20 flex justify-between ${isHeaderScrolled ? "" : defaultHeaderClass}`}>
          <div className="flex items-center lg:hidden flex-1">
            <MenuBar />
          </div>

          <div className="flex items-center">
            <Logo />
          </div>

          <div className="flex-[5] hidden lg:flex justify-center mx-4">
            <Navigation />
          </div>

          <div className="flex flex items-center justify-end text-slate-700 dark:text-slate-100">
            {/* <SearchModal />
            {/* <NotifyDropdown />*/}
            <AvatarDropdown /> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav2Logged;
