import React from "react";
import { Helmet } from "react-helmet";

import bannerimage from "./images/bannerimage.webp";
import OurServicesSlider from "components/Sections/ourservices/ourservicesslider";
import "./services.css";
import unlocktera from "./images/unlocktera.webp";
import searchfrad from "./images/searchgeadient.png";
import scopefrad from "./images/scopegead.png";
import persongrad from "./images/peaopelsgrad.png";
import handgrad from "./images/handshakegrad.png";
import bloodbankcircle from "./images/Frame-257.png";
import diagnosiscircle from "./images/Frame-255.png";
import medicaldevice from "./images/Frame-254.png";
import hospitalcircle from "./images/Frame-258.png";
import anouncement from "./images/Frame-256.png";
import shoppingcart from "./images/Frame-253.png";

const PageHomeDemo4: React.FC = () => {
  return (
    <div className="nc-PageHomeDemo4 relative">
      <Helmet>
        <title>
          {" "}
          Revolutionizing Healthcare Industry With Our Tailored Service Hub
        </title>
        <meta
          name="description"
          content="Our premier platform offers a wide range of services from HR and staffing to branding and promoting your healthcare business. Ready to create an impact?"
        />
      </Helmet>

      <div className="lg:py-30 py-10 md:py=16 sm:py-16 background-white-shadow">
        <div className="container flex flex-col-reverse lg:flex-row lg:space-x-4 space-y-10">
          <div className="w-full lg:w-1/2 mb-4 lg:mb-0 sm:py-16 flex items-center justify-center">
            <div className="flex flex-col space-x-3 items-start justify-center space-y-4 mt-10 lg:mt-0 md:mt-0 sm:mt-0 ">
              <h3 className="font-black regular title-custom title-heading-banner montserrat">
                Where
                <span className="font-sky-blue bolders">
                  {" "}
                  Excellence in Healthcare
                </span>{" "}
                Begins.
              </h3>
              <h5 className="font-sky font-normal title-custom montserrat">
                Tera Connects serves as a hub, effortlessly connecting vendors
                and clients for streamlined collaboration and success.
              </h5>
            
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            <div className="flex justify-center items-center">
              <img src={bannerimage} alt="Service" className="cards-home" />
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto background-lineargradient-blue">
        <div className="container lg:flex justfy-center lg:py-40 sm:py-16 py-20">
          <div className="flex justify-center items-center flex-col space-y-10">
            <h1 className="font-white font-light title-custom  montserrat text-center ">
              Embark <span className="bolder">On A Journey</span> Where Your
              Healthcare Dreams Take Flight.
            </h1>

          
          </div>
        </div>
      </div>

      <div className=" mx-auto background-white-darkshadow lg:py-40 py-10 md:py=16 sm:py-16">
        <div className="container lg:flex flex flex-col justify-center items-center head-home-sec">
          <div className="flex flex-col justify-center items-center space-y-10">
            <h2 className="font-black font-semibold text-center title-custom montserrat">
              Tailored Services for Medical Industry
            </h2>
            <h4 className="font-sky font-normal title-custom montserrat">
              Transforming the Medical Ecosystem across India for a Better
              Tomorrow.
            </h4>
          </div>
        </div>
        <div className="sec-padding-small"></div>
        <div className="container mx-auto gap-8 flex flex-col ">
          <div className="gap-8 lg:columns-4 md:columns-2 sm:columns:1">
            <div className="flex flex-col justify-center items-center cards-home gap background-white sec-padding-small">
              <img src={handgrad} />
              <h6 className="text-center font-black bolds title-custom montserrat ">
                For Partners
              </h6>
            
            </div>

            <div className="flex flex-col justify-center items-center cards-home gap background-white sec-padding-small">
              <img src={persongrad} />
              <h6 className="text-center font-black bolds title-custom montserrat ">
                {" "}
                For Clients
              </h6>
        
            </div>

            <div className="flex flex-col justify-center items-center cards-home gap background-white sec-padding-small">
              <img src={scopefrad} />
              <h6 className="text-center font-black bolds title-custom montserrat ">
                For Clinicians
              </h6>
          
            </div>

            <div className="flex flex-col justify-center items-center cards-home gap background-white sec-padding-small">
              <img src={searchfrad} />
              <h6 className="text-center font-black bolds title-custom montserrat ">
                For Recruitment Agencies
              </h6>
            
            </div>
          </div>
        </div>
      </div>

      <div className=" mx-auto background-white sec-padding">
        <div className="container lg:flex head-home-sec gap-extra">
          <div className="lg:w-1/2  head-home-sec-centre gap vector-background">
            <h2 className="font-black bolds title-custom montserrat">
              Crafting The Future of{" "}
              <span className="font-sky-blue bolders">Medical Industry</span>
            </h2>
            <h5 className="font-sky regular title-custom montserrat">
              Welcome to the Tera Connects Experience—a dynamic space where
              innovation meets collaboration. As we unlock the door to a new era
              in healthcare, we invite partners to join our community.
            </h5>
            <h5 className="font-sky regular title-custom montserrat">
              Together, we can shape the future of the Medical Industry and
              embark on a journey of shared success. Discover the power of
              connection with Tera Connects.
            </h5>
          </div>
          <div className="lg:w-1/2 head-home-sec-centre">
            <img src={unlocktera} alt="Service" className="" />
          </div>
        </div>
      </div>

      <div className=" mx-auto background-blue sec-padding sec-padding">
        <div className="container lg:flex head-home-sec">
          <div className="head-home-sec-centre gap">
            <h2 className="font-white bolds title-custom montserrat">
              Discover Our Comprehensive Solutions{" "}
            </h2>
            <h4 className="font-white regular title-custom montserrat">
              Explore the Services Provided on Our Healthcare Platform to Find
              Tailored Solutions for Your Needs.
            </h4>
          </div>
        </div>
        <div className="sec-padding-small"></div>
        <div className="container ">
          <OurServicesSlider />
        </div>
      </div>


      <div className=" mx-auto background-white-darkshadow sec-padding sec-padding">
        <div className="container lg:flex flex flex-col justify-start items-start head-home-sec">
          <div className="flex flex-col justify-start items-start gap">
            <h2 className="font-black bolds title-custom montserrat">
              One Platform, Multiple Services
            </h2>
            <h4 className="font-sky regular title-custom montserrat">
              We've collaborated with diverse healthcare providers, curating a
              comprehensive list of services tailored to meet every need.
            </h4>
          </div>
        </div>
        <div className="sec-padding-small"></div>
        <div className="container mx-auto gap-8 flex flex-col ">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 ">
            <div className="flex flex-col justify-center items-center cards-home gap background-lineargradient-blue-yellow p-10">
              <img src={hospitalcircle} />
              <h6 className="text-center font-black bold title-custom montserrat ">
                Easy Recruitment and ATS
              </h6>
            </div>

            <div className="flex flex-col justify-center items-center cards-home gap background-lineargradient-blue-yellow  p-10">
              <img src={diagnosiscircle} />
              <h6 className="text-center font-black bold title-custom montserrat ">
                Training Courses and Fellowship
              </h6>
            </div>

            <div className="flex flex-col justify-center items-center cards-home gap background-lineargradient-blue-yellow  p-10">
              <img src={medicaldevice} />
              <h6 className="text-center font-black bold title-custom montserrat ">
                Clinical Establishment and Maintenance Services
              </h6>
            </div>

            <div className="flex flex-col justify-center items-center cards-home gap background-lineargradient-blue-yellow  p-10">
              <img src={bloodbankcircle} />
              <h6 className="text-center font-black bold title-custom montserrat ">
                Account/Branch Management
              </h6>
            </div>

            <div className="flex flex-col justify-center items-center cards-home gap background-lineargradient-blue-yellow  p-10">
              <img src={anouncement} />
              <h6 className="text-center font-black bold title-custom montserrat ">
                Branding and Promotions
              </h6>
            </div>

            <div className="flex flex-col justify-center items-center cards-home gap background-lineargradient-blue-yellow  p-10">
              <img src={shoppingcart} />
              <h6 className="text-center font-black bold title-custom montserrat ">
                Medical e-Commerce
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHomeDemo4;
